import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { SelectInput } from 'shared-library';
import {
  ButtonName,
  ButtonTime,
  ButtonWrapper,
  CancelConfirmButton,
  CheckinWrapper,
  ConfirmButton,
  ConfirmButtonWrapper,
  ConfirmHeader,
  ConfirmInput,
  ConfirmWrapper,
  Header,
  LoadingText,
  Logo,
  LogoutButton,
  NoBookingsText,
  PatientButton,
  SubHeader,
} from './checkinStyles';

const Checkin = (props) => {
  const roles = props.userData['https://prenuvo.com/roles'];
  const userLocation = props.userData.location;
  const hasCheckinRole =
    (roles.includes('checkin') || roles.includes('technologist')) && !roles.includes('admin');
  const initialLocation =
    hasCheckinRole && userLocation?.length ? userLocation[0] : 'can-bc-vancouver';

  const CancelToken = axios.CancelToken;

  // TODO: specifiy the default value for multisite location at central configuration
  const [locationID, setLocationID] = useState(initialLocation);
  const [locationSelect, setLocationSelect] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [users, setUsers] = useState({});
  const [patientBtns, setPatientBtns] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [validateDOB, setValidateDOB] = useState(null);
  const [studyID, setStudyID] = useState(null);
  const [confirmVal, setConfirmVal] = useState('');
  const [validationError, setValidationError] = useState(false);

  const mapPatientBtns = (bookings) =>
    bookings.filter((i) => !!i.patient).map((booking) => renderButton(booking));

  const handleAsyncError = (res, cancel) => {
    try {
      if (res.status >= 500) {
        throw Error(res.error);
      }

      cancel();

      if (res.status < 400) {
        return res;
      }
    } catch (err) {
      console.error('Error (1):', err);
    }
  };

  const handleButtonClick = (studyID, DOB) => {
    if (roles.includes('technologist')) {
      window.open(`/admin/study/${studyID}/medical-history/technologist`, '_blank');
    } else if (roles.includes('technician') || roles.includes('radiologist')) {
      window.location.href = `/patient/study/${studyID}/check-in/embed`;
    } else {
      setValidateDOB(DOB);
      setStudyID(studyID);
      setConfirm(true);
    }
  };

  const handleConfirm = (value) => {
    if (value === validateDOB) {
      window.location = `/patient/study/${studyID}/check-in/embed`;
    } else {
      setValidationError(true);
    }
  };

  const getLocations = async () => {
    const { data } = await axios({
      method: 'GET',
      url: '/api/v1/booking/locations',
    });

    if (userLocation.includes('can-bc-001')) {
      // Add old format location code for Vancouver
      userLocation.push('can-bc-vancouver');
    }

    const { locations } = data;

    const locationSelect = locations
      .filter((location) => location.assistedBooking)
      .filter((location) => (hasCheckinRole ? !!userLocation.includes(location.locationID) : true))
      .map((location) => {
        return {
          value: location.locationID,
          label: `${location.address.name}, ${location.address.city} (${location.locationID})`,
        };
      });

    setLocationSelect(locationSelect);
  };

  const getBookings = async (locationID) => {
    let cancel;

    try {
      const url = `/admin/studies/all?location=${locationID}`;

      return await axios
        .get(url, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((err) => handleAsyncError(err, cancel))
        .then((res) => res.data)
        .catch((error) => {
          throw Error(error.message);
        });
    } catch (err) {
      console.error('Error (2):', err);
    }
  };

  const getUsers = async (prenuvoIDs) => {
    const filteredIDs = prenuvoIDs.filter((id) => !!id);
    const args = `?user_id=${filteredIDs.join(',')}`;
    const url = `${window.PRENUVOCONFIG.Routes.PII}/api/v1/userV2/${args}`;

    const userData = await axios({
      method: 'GET',
      url: `${url}`,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error (3)', err);
      });

    const userDataObj = await userData.reduce(
      (obj, user) => ({ ...obj, [user.user_id]: user }),
      {},
    );

    setUsers(userDataObj);
    setLoadingBookings(false);
  };

  const renderButton = (item) => {
    let initials = `${item.patient.firstname[0]} ${item.patient.lastname[0]}`;
    let DOB = item.patient.dob.split('-').slice(1).join('');
    const utcStarts = item.booking.map(( {utcStart} ) => utcStart)
    let time = Number(utcStarts.length > 0 ? Math.min(utcStarts) : 0) * 1000;
    let hour = new Date(time).getHours().toString();
    let minutes = new Date(time).getMinutes().toString();
    let localTime;

    if (minutes === '0') {
      minutes += '0';
    }

    if (Number(hour) > 12) {
      localTime = `${Number(hour) - 12}:${minutes} PM`;
    } else if (hour === '12') {
      localTime = `${hour}:${minutes} PM`;
    } else {
      localTime = `${hour}:${minutes} AM`;
    }

    return (
      <PatientButton
        onClick={() => {
          handleButtonClick(item.studyID, DOB);
        }}
        key={item.studyID}
      >
        <ButtonName>{initials}</ButtonName>
        <ButtonTime>{localTime}</ButtonTime>
      </PatientButton>
    );
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    setBookings([]);
  }, [locationID]);

  useEffect(() => {
    if (!bookings.length && !loadingBookings) {
      setLoadingBookings(true);
      setUsers({});
      setPatientBtns([]);

      (async () => {
        const bookingList = await getBookings(locationID);

        if (bookingList && bookingList.hasOwnProperty('studies') && bookingList.studies.length) {
          setBookings(bookingList.studies);

          const prenuvoIDs = bookingList.studies.map((booking) => booking.patient);
          getUsers(prenuvoIDs);
        } else {
          setLoadingBookings(false);
        }
      })();
    }
  }, [bookings]);

  useEffect(() => {
    if (Object.keys(users).length) {
      const userBookings = bookings.reduce((newBookings, booking) => {
        const newBooking = { ...booking, patient: users[booking.patient] };
        return [...newBookings, newBooking];
      }, []);

      const mappedBtns = mapPatientBtns(userBookings);

      setPatientBtns(mappedBtns);
    }
  }, [users]);

  // Add old format location code for Vancouver
  const selectedLocationID = locationID === 'can-bc-001' ? 'can-bc-vancouver' : locationID;

  return (
    <CheckinWrapper>
      <LogoutButton href="/logout">Log Out</LogoutButton>

      <Logo>prenuvo</Logo>

      <Header>Check In for Location:</Header>

      <SelectInput
        id="location"
        options={locationSelect}
        value={locationSelect.filter((location) => location.value === selectedLocationID)}
        onChange={(e) => setLocationID(e.value)}
        width="30%"
        variant="primary"
      />

      {!confirm && (
        <SubHeader>
          If you do not see your initials or appointment time below, please check in with the front
          desk.
        </SubHeader>
      )}

      {confirm ? (
        <ConfirmWrapper>
          <ConfirmHeader>
            {validationError
              ? `Incorrect value, please ensure format is MMDD.`
              : `Enter your date of birth (MMDD):`}
          </ConfirmHeader>

          <ConfirmInput value={confirmVal} onChange={(e) => setConfirmVal(e.target.value)} />

          <ConfirmButtonWrapper>
            <ConfirmButton onClick={() => handleConfirm(confirmVal)}>Check In</ConfirmButton>

            <CancelConfirmButton
              onClick={() => {
                setValidationError(false);
                setConfirm(false);
              }}
            >
              Go Back
            </CancelConfirmButton>
          </ConfirmButtonWrapper>
        </ConfirmWrapper>
      ) : (
        <ButtonWrapper>
          {patientBtns.length ? (
            patientBtns
          ) : loadingBookings ? (
            <LoadingText>Loading patients...</LoadingText>
          ) : (
            <NoBookingsText>No scans available for today!</NoBookingsText>
          )}
        </ButtonWrapper>
      )}
    </CheckinWrapper>
  );
};

export default Checkin;
