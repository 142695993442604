import styled from 'styled-components';

export const ButtonName = styled.span`
  display: flex;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 800;
  padding: 0.25rem 0;
  color: #FFF;
  margin: 0 auto;
`;

export const ButtonTime = styled.span`
  display: flex;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem 0;
  color: #FFF;
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 90%;
  max-width: 90%;
  min-height: 100%;
  padding: 2rem;
  margin: 1rem 0;
`;

export const CheckinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #FFF;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

export const ConfirmButton = styled.button`
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #2D7687;
  color: #FFF;
  padding: 0.25rem;
  margin: 0.5rem;
  margin-bottom: 0;
  text-align: center;
`;

export const CancelConfirmButton = styled(ConfirmButton)``;

export const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
  margin: 1rem auto;
  width: 40%;
`;

export const ConfirmHeader = styled.h5``;

export const ConfirmInput = styled.input`
  display: flex;
  margin: 1rem auto;
  width: 30%;
  border: 1px solid rgba(0,0,0,0.3);
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 2rem;
  margin: 1rem 0;
`;

export const Header = styled.h4`
  font-weight: 500;
  margin: 1rem auto;
  text-align: center;
`;

export const LoadingText = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const Logo = styled.h1`
  font-size: 3rem;
  color: #2D7687;
  margin: 2rem auto;
`;

export const LogoutButton = styled.a`
  width: 4rem;
  height: 1.5rem;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #2D7687;
  color: #FFF;
  align-self: flex-start;
  padding: 0.25rem;
  margin: 0.5rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  text-align: center;
`;

export const NoBookingsText = styled(LoadingText)``;

export const PatientButton = styled.button`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 8rem;
  min-height: 8rem;
  max-width: 8rem;
  max-height: 8rem;
  border: 2px solid #AAA;
  border-radius: 50%;
  background-color: #2D7687;
  margin: 1rem;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

export const SubHeader = styled.span`
  margin-top: 1rem;
  max-width: 50%;
  text-align: center;
  width: 50%;
`;
